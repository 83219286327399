// sass-lint:disable class-name-format
@import '../styles/common';

@import '../components/Tag';
@import '../components/HomeHeader';
@import '../components/CardContainer';
@import '../components/CtaButton';
@import '../components/IconText';
@import '../components/CardChild';
@import '../components/SectionContainer';
@import '../components/Carrousel';
@import '../components/Card';
@import '../components/CardIcon';
@import '../components/RecoContainer';
@import '../components/InitialInformation';
@import '../components/FloatPanelCard';
@import '../components/Search';
@import '../components/GlobalTooltip';
@import '../components/Landing';

.sc-home {
  .sc-view-home {
    position: relative;
    width: 100%;
    margin: auto;
  }
}

.sc-view-home .sc-find-information-cbt {
  background-image: url('https://http2.mlstatic.com/frontend-assets/sc-seller-university-frontend/gradient-E1F4FC.svg'),
    linear-gradient($andes-white, $su-transparent);
  background-position: right -80px;
  background-repeat: no-repeat !important;
  background-origin: border-box;
  background-size: cover;
  padding: 80px 40px;

  .sc-section-container-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1129px;
    padding: 0 0 32px;
    margin: 40px auto 15px;

    .andes-typography {
      margin: auto;
      width: 100%;
    }
  }

  &-cards {
    max-width: 1129px;
  }
}

.sc-view-home
  .sc-section-container.sc-find-information-cbt
  + .sc-section-container {
  .sc-section-container-title {
    text-align: center;
    width: 100%;
    margin-bottom: 19px;
  }
}

.sc-view-home .sc-find-information {
  background-image: url('https://http2.mlstatic.com/frontend-assets/sc-seller-university-frontend/gradient-F5Mobile.svg'),
    linear-gradient($andes-white, $su-transparent);
  background-size: 108%;
  background-repeat: no-repeat;
}
